import * as React from 'react';
import Logo from '../assets/logo.svg';
import { Helmet } from 'react-helmet';
import OGPIMAGE from '../images/ogp.png';

const Home = () => {
	return (
		<div className='font-body'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>AUX（アクス）</title>
				<meta
					name='description'
					content='アクスは、デジタルクリエイティブと人材育成を軸に、お客様の課題解決を担うソリューションカンパニーです。'
				/>
				<meta property='og:title' content='AUX（アクス）' />
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://auxco.jp' />
				<meta property='og:image' content={OGPIMAGE} />
			</Helmet>
			<header className='flex justify-center items-center min-h-screen'>
				<div className='p-6 tetxt-ceter'>
					<div className='flex justify-center px-2 md:px-0'>
						<div className='px-4 md:px-0'>
							<Logo width='440px' height='250px' />
						</div>
					</div>
					<p className='text-sm text-center leading-8 mt-12 sm:text-base sm:leading-8'>
						アクスは、デジタルクリエイティブと人材育成を軸に、お客様の課題解決を担うソリューションカンパニーです。
						<br />
						ただ今、ホームページを準備しています。
						<br className='md:hidden' />
						もうしばらくお待ちください。
					</p>
				</div>
			</header>
			<main className='px-3'>
				<section className='container max-w-xl mx-auto mb-32'>
					<h2 className='text-2xl md:text-3xl font-semibold text-center mb-8'>業務内容</h2>
					<ul className='text-sm leading-8 md:text-base md:leading-8'>
						<li>・デジタルコンテンツ、Webサイトの企画、制作</li>
						<li>・Webメディア、SNSによる企業プロモーションの企画、運用</li>
						<li>・UI/UXデザイン、設計</li>
						<li>・ロゴデザイン、グラフィックデザイン、VI/CIの企画提案</li>
						<li>・スマートフォンアプリの企画、開発</li>
						<li>・プロダクトの企画、デザイン、開発</li>
						<li>・動画の企画、制作</li>
						<li>・就業支援</li>
						<li>・フリーランス独立支援</li>
					</ul>
				</section>
				<section className='container max-w-xl mx-auto mb-32'>
					<h2 className='text-2xl md:text-3xl font-semibold text-center mb-8'>お仕事のお問合せ・ご相談</h2>
					<div className='text-center leading-8'>
						<p className='text-sm md:text-base mb-1'>
							※ただ今、準備中につきメールにてお問合せを受け付けています。
						</p>
						<p className='mb-3'>
							<a
								className='text-primary underline'
								href='m&#97;i&#108;t&#111;:c&#111;&#110;t&#97;&#99;&#116;&#64;au&#120;c&#111;&#46;&#106;p'
							>
								c&#111;&#110;t&#97;&#99;&#116;&#64;au&#120;c&#111;&#46;&#106;p
							</a>
						</p>
						<p className='text-sm leading-6 md:text-base md:leading-6'>
							尚、お急ぎの方は、連絡先電話番号などをご記載いただければ、
							<br className='hidden md:block' />
							弊社担当者よりお電話させていただきます。
						</p>
					</div>
				</section>
				<section className='container max-w-xl mx-auto mb-28'>
					<h2 className='text-2xl md:text-3xl font-semibold text-center mb-8'>会社概要</h2>
					<table className='text-sm md:text-base w-full'>
						<tbody>
							<tr className='border-t border-gray-200'>
								<th className='font-medium text-left py-4 px-4 md:px-6'>会社名</th>
								<td className='py-4 px-4 md:px-6'> 株式会社アクス（AUX &amp; Co.）</td>
							</tr>
							<tr className='border-t border-gray-200'>
								<th className='font-medium text-left py-4 px-4 md:px-6'>所在地</th>
								<td className='py-4 px-4 md:px-6'>山梨県甲府市国母4-17-27</td>
							</tr>
							<tr className='border-t border-gray-200'>
								<th className='font-medium text-left py-4 px-4 md:px-6'>設立</th>
								<td className='py-4 px-4 md:px-6'>2021年10月</td>
							</tr>
							<tr className='border-t border-b border-gray-200'>
								<th className='font-medium text-left py-4 px-4 md:px-6'>代表取締役</th>
								<td className='py-4 px-4 md:px-6'>長田 淳</td>
							</tr>
						</tbody>
					</table>
				</section>
			</main>
			<footer className='py-4 text-center'>
				<small className='text-xs'>© AUX &amp; Co. All Rights Reserved.</small>
			</footer>
		</div>
	);
};

export default Home;
